var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{ref:"tbod"},[_c('a-page-header',{attrs:{"title":"Check CV","sub-title":_vm.welcomeMSG}},[_c('template',{slot:"tags"},[_c('a-tag',{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.waitCV)+" en attente de traitement ")])],1),_c('template',{slot:"extra"},[_c('a-button',{key:"1",attrs:{"type":"primary"},on:{"click":_vm.logout}},[_vm._v(" Se deconnecter ")])],1)],2),_c('a-col',{style:({
      overflow: 'auto',
      height: '90vh',
      position: 'fixed',
      left: 0,
      width: _vm.myWidth
    }),attrs:{"span":12}},[(_vm.load)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1):_c('div',[(_vm.cvData.cv)?_c('div',[(_vm.cvData.cv == 'none')?_c('div',[_vm._v("no more cv")]):_c('div',[_c('pdf',{ref:"pdf",attrs:{"src":_vm.cvData.cv[0]['url']}},[_c('template',{slot:"loading"},[_vm._v("loading content here...")])],2)],1)]):_c('div',[_vm._v("Chargement")])])]),_c('a-col',{style:({
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      right: 0,
    }),attrs:{"span":12}},[(_vm.load)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}})],1):_c('div',[_c('a-row',{style:({
          height: '100vh',
        }),attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[_c('vue-form',{key:_vm.componentKey,attrs:{"data":_vm.formData},on:{"complete":_vm.complete}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }