<template>
  <div class="vue-form__form">
    <form-question
      :number="currentQuestionIndex + 1"
      :question="question"
      :show-number="question.type !== 'submit'"
    ></form-question>

    <form-answer
      @answer="registerAnswer"
      :question="question"
      @submit="submit"
    ></form-answer>

    <div v-if="showBack()">
      <a-button type="primary" @click="goBack" shape="circle" icon="left" />
    </div>
  </div>
</template>

<script>
import FormQuestion from "./FormQuestion";
import FormAnswer from "./FormAnswer";

export default {
  props: {
    questions: Array,
    answers: [],
  },
  data() {
    return {
      userAnswers: [],
      currentQuestionIndex: 0,
      previousQuestionsIndex: [0],
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestionIndex];
    },
  },
  components: {
    FormQuestion,
    FormAnswer,
  },
  methods: {
    showBack() {
      if (this.previousQuestionsIndex.length > 1) return true;
      else return false;
    },
    goBack() {
      var destination = this.previousQuestionsIndex[
        this.previousQuestionsIndex.length - 2
      ];
      //console.log(destination);
      this.previousQuestionsIndex.pop();
      this.currentQuestionIndex = destination;
    },
    submit() {
      this.$emit("complete", this.userAnswers);
    },

    changeQuestion(newQuestionIndex) {
      if (!this.questions[newQuestionIndex]) {
        this.submit();
        return;
      }
      this.previousQuestionsIndex.push(newQuestionIndex);
      this.currentQuestionIndex = newQuestionIndex;
    },

    registerAnswer(answerData) {
      const question = answerData.question;
      const answer = answerData.answer;

      const action = answer.action || question.action;

      this.userAnswers.push({
        id: question.id,
        question: question.question,
        answer: answer.answer || answer,
      });

      if (!action || action === "continue") {
        this.changeQuestion(this.currentQuestionIndex + 1);
        return;
      }

      if (action === "skip") {
        const questionIndex = this.questions.findIndex(
          (q) => q.id === answer.skipTo
        );
        this.changeQuestion(questionIndex);
      }
    },
    enterListener(e) {
      // Enter key === 13
      if (e.which == 8 || e.which == 37) {
        this.goBack();
      } else return;
    },
  },
  created() {
    window.addEventListener("keyup", this.enterListener);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.enterListener);
  },
};
</script>

<style lang="scss" scoped>
.vue-form__form {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: auto;

  @media (min-width: 768px) {
    width: calc((100% / 5) * 4);
  }
}
</style>
