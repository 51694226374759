<template>
  <a-row  ref="tbod">
    <a-page-header title="Check CV" v-bind:sub-title="welcomeMSG">
      <template slot="tags">
        <a-tag color="blue"> {{ waitCV }} en attente de traitement </a-tag>
      </template>
      <template slot="extra">
        <a-button key="1" type="primary" @click="logout">
          Se deconnecter
        </a-button>
      </template>
    </a-page-header>
    <a-col :span="12" :style="{
        overflow: 'auto',
        height: '90vh',
        position: 'fixed',
        left: 0,
        width: myWidth
      }">
      <div v-if="load">
        <a-icon type="loading" />
      </div>
      <div v-else>
        <div v-if="cvData.cv">
          <div v-if="cvData.cv == 'none'">no more cv</div>
          <div v-else>
            <pdf ref="pdf" :src="cvData.cv[0]['url']">
              <template slot="loading">loading content here...</template>
            </pdf>
          </div>
        </div>
        <div v-else>Chargement</div>
      </div>
    </a-col>
    <a-col
      :span="12"
      :style="{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        right: 0,
      }"
    >
      <div v-if="load">
        <a-icon type="loading" />
      </div>
      <div v-else>
        <a-row
          type="flex"
          justify="space-around"
          align="middle"
          :style="{
            height: '100vh',
          }"
        >
          <a-col :span="20">
            <vue-form
              :data="formData"
              @complete="complete"
              :key="componentKey"
            ></vue-form>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import pdf from "vue-pdf";
import axios from "axios";
import VueForm from "@/components/VueForm";

export default {
  name: "check",
  props: ["user", "welcomeMSG"],
  data() {
    return {
      waitCV: "??",
      myWidth : "inherit",
      formData: {},
      cvData: {},
      componentKey: 0,
      load: true,
    };
  },
  components: {
    pdf,
    VueForm,
  },
  updated: function() {
    Vue.nextTick(() => {
      console.log(this.$refs.pdf);
      console.log(this.$refs.pdf.$parent.$el.clientHeight);
      console.log(this.$refs.pdf.$parent.$el.clientWidth);

      this.myWidth = parseInt(210 * this.$refs.pdf.$parent.$el.clientHeight / 297) + 'px'

      if(this.$refs.tbod.scrollHeight > this.$refs.tbod.clientHeight)
        console.log(true)
    });
  },  
  methods: {
    async logout() {
      await window.m.user.logout();
      this.$router.push({
        name: "login",
      });
    },
    complete(data) {
      // Send to database here
      this.load = true;

      axios
        .post(process.env.VUE_APP_APIURL + "/submitCV", {
          id: this.user,
          cv: this.cvData.id,
          data: data,
        })
        .then((response) => {
          axios
            .post(process.env.VUE_APP_APIURL + "/cv", { id: this.user })
            .then((response) => {
              this.cvData = response.data;
              this.load = false;
              this.waitCV = response.data.all;
            })
            .catch((e) => {
              this.errors.push(e);
              this.buttonNotBLocked = true;
            });
          if (response.data.reviewed) {
            this.componentKey += 1;
          }
        })
        .catch((e) => {
          this.errors.push(e);
          this.buttonNotBLocked = true;
        });
    },
  },
  created() {
    if (typeof this.user != "undefined") {
      axios
        .get(process.env.VUE_APP_APIURL + "/questions")
        .then((response) => {
          this.formData = response.data;
          this.load = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.buttonNotBLocked = true;
        });

      axios
        .post(process.env.VUE_APP_APIURL + "/cv", { id: this.user })
        .then((response) => {
          this.cvData = response.data;
          this.load = false;
          this.waitCV = response.data.all;
        })
        .catch((e) => {
          this.errors.push(e);
          this.buttonNotBLocked = true;
        });
    } else {
      this.$router.push({
        name: "login",
      });
    }
  },
};
</script>
